

































































































import {Component, Vue, Prop, Mixins} from 'vue-property-decorator';
import Mixin from './Mixin.vue';

@Component({
  name: 'ProductCardDetail'
})
export default class Default extends Mixins(Mixin) {
}
