















































import {Component, Vue, Prop, Mixins} from 'vue-property-decorator';
import Mixin from './Mixin.vue';
import _ from 'lodash';

@Component({
  name: 'ProductSideImage'
})
export default class ProductSideImage extends Mixins(Mixin) {
  @Prop() private applyCoverage!: any;

  private apply() {
    _.forEach(this.product.fields.metadata, (data) => {
      if (data.startsWith('COVERAGE_CODE')) {
        const fact = data.split(':').pop();
        const factData = fact.split('#');
        this.applyCoverage({id: `${this.product.code}_${factData[0]}`, val: factData[1]});
        return false;
      }
    });
  }
}
