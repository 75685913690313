

import _ from 'lodash';
import {Component, Vue, Prop} from 'vue-property-decorator';
import {State, Getter} from 'vuex-class';
import EventBus from '@/common/EventBus';
import Util from '@/utils/Util';

@Component({
  name: 'ProductCard',
})
export default class Mixin extends Vue {
  @Prop() protected product: any;
  @Prop({default: 0}) protected index!: number;
  @Prop() protected isSelected!: boolean;
  @Prop() protected selectable!: boolean;
  @Prop() protected isRecommended!: boolean;
  @Prop() protected isRenewal!: boolean;
  @Prop({default: () => ({})}) protected displayState!: any;
  @Prop({ default: false }) protected minimized!: boolean;
  @Prop() protected flat!: boolean;
  @Prop() protected actionText!: boolean;
  @Prop() protected formatCompleted!: boolean;
  @Prop({ default: false }) protected isFiltered!: boolean;
  @State protected cms: any;
  @State protected app: any;
  @Getter('cms/getProductContentByProduct') protected getProductContentByProduct: any;

  protected content: any;

  get isOther() {
    return _.get(this.content, 'type') === 'otherProduct';
  }

  get hasDetails() {
    return this.content && (this.content.details && !Util.isEmptyString(this.content.details.replace(/(<([^>]+)>)/ig, ''))) ||
      !_.isEmpty(this.content.additionalInformation) ||
      !_.isEmpty(this.content.questionnaire);
  }

  get buttonText() {
    if (this.selectable || !this.isOther) {
      return this.isSelected ?
        this.content.actionTextSelected || this.$t('catalogue.productSelection.unselect') :
        this.content.actionText || this.$t('catalogue.productSelection.select');
    } else {
      return this.actionText || this.content.actionText;
    }
  }

  protected clickHandlerCard() {
    if (this.isSelected || (this.selectable && !this.isOther)) {
      this.toggleSelection();
    }
  }

  protected clickHandlerButton() {
    if (Util.isURL(this.content.action)) {
      this.openNewTab(this.content.action);
    } else if (_.get(this.content, 'action')) {
      EventBus.$emit('otherProductAction', this.content);
    }
  }

  protected toggleSelection() {
    this.$emit('onToggleSelection', {...this.product, index: this.index});
  }

  protected openNewTab(url) {
    window.open(url, '_blank');
  }

  protected onInfoBtnClick() {
    this.$emit('onInfoBtnClick', this.content);
  }

  protected read() {
    if (this.displayState.details) {
      this.onInfoBtnClick();
    } else {
      EventBus.$emit('activateCardStatus', {
        type: 'details',
        isFiltered: this.isFiltered,
        index: this.displayState.index
      });
    }
  }

  private created() {
    this.content = this.product.title ? this.product : this.getProductContentByProduct(this.product);
  }
}
